import styled from 'styled-components';

import { COLORS, fontMixin } from '../../../stylesheets';

export const LogotypeContainer = styled.div`
	display: flex;
	align-items: center;
	margin-inline: auto;
	${fontMixin(20, 500)};
	text-transform: uppercase;
	color: ${COLORS.BLUE_100};
	border-bottom: 1px solid ${COLORS.BLUE_20};

	span {
		font-weight: 200;
	}
`;
