import { useEffect, useRef, useState } from 'react';

import {
	ClusterOutlined,
	DashboardOutlined,
	DeleteOutlined,
	DiffOutlined,
	DollarOutlined,
	FileZipOutlined,
	FormOutlined,
	MessageOutlined,
	ProfileOutlined,
	SafetyOutlined,
	UsergroupAddOutlined,
} from '@ant-design/icons';
import { Badge, MenuProps } from 'antd';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate } from 'react-router-dom';

import { MenuExtra } from './Menu.styled';
import { useStores } from '../../../../../stores';
import { BellIcon } from '../../../ProtectedRoute/ProtectedRoute.styled';

export const Menu = observer(() => {
	const { authStore, notificationsStore, tourStore } = useStores();
	const { current: currentUser } = authStore;

	const location = useLocation();
	const navigate = useNavigate();
	const [currentPath, setCurrentPath] = useState<Array<string>>(['/']);

	// Reference
	const menuRef = useRef(null);

	useEffect(() => {
		// ДОбавляем меню в тур
		tourStore.addTarget('menu', menuRef);
	}, []);

	useEffect(() => {
		setCurrentPath([location.pathname]);
	}, [location]);

	const handleChangeMenu: MenuProps['onClick'] = (e) => {
		// Записываем текущий урл
		setCurrentPath(e.keyPath);

		// Переходи по урлу
		navigate(`${e.keyPath.join('/')}`);
	};

	const getItems = (): MenuProps['items'] => {
		const menuUser: MenuProps['items'] = [];

		menuUser.push({
			type: 'group',
			label: 'Основное',
			children: [
				{
					key: '/cabinet',
					label: 'Рабочий стол',
					icon: <DashboardOutlined />,
				},
				{
					key: '/cabinet/messenger',
					label: 'Сообщения',
					icon: <MessageOutlined />,
				},
				{
					key: '/cabinet/task-exchange',
					label: 'Биржа задач',
					icon: <DollarOutlined />,
				},
				{
					key: '/cabinet/notifications',
					label: (
						<>
							Уведомления <Badge dot={notificationsStore.isExistNotShow} />
						</>
					),
					icon: <BellIcon />,
				},
			],
		});

		if (currentUser.role.isHeadEmployee) {
			menuUser.push({
				type: 'group',
				label: 'Администрирование',
				children: [
					{
						key: '/cabinet/agreements',
						label: 'Договоры',
						icon: <FormOutlined />,
					},
					{
						key: '/cabinet/agreement-file-templates',
						label: 'Договоры (Файлы)',
						icon: <FileZipOutlined />,
					},
					{
						type: 'divider',
					},
					{
						key: '/cabinet/objects',
						label: 'Объекты',
						icon: <ProfileOutlined />,
					},
					{
						key: '/cabinet/objects-drafts',
						label: 'Объекты (Черновики)',
						icon: <ProfileOutlined />,
					},
					{
						key: '/cabinet/objects-delete',
						label: 'Удаленные объекты',
						icon: <DeleteOutlined />,
					},
					{
						type: 'divider',
					},
					{
						key: '/cabinet/employers',
						label: 'Сотрудники',
						icon: <UsergroupAddOutlined />,
					},
					{
						key: '/cabinet/roles',
						label: 'Должности',
						icon: <SafetyOutlined />,
					},
					{
						key: '/cabinet/contractors',
						label: 'Контрагенты',
						icon: <ClusterOutlined />,
					},
					{
						key: '/cabinet/project-documentations',
						label: 'Проектная документация',
						icon: <DiffOutlined />,
					},
				],
			});
		}

		/*[

      {
        type: 'group',
        label: 'Администрирование',
        children: [
          /!*
          {
            key: '/cabinet/project-documentations',
            label: 'Проектная документация',
            icon: <DiffOutlined />,
          },*!/
          {
            key: '/cabinet/employers',
            label: 'Сотрудники',
            icon: <UsergroupAddOutlined />,
          },
          {
            key: '/cabinet/contractors',
            label: 'Контрагенты',
            icon: <ClusterOutlined />,
          },
          /!*{
            key: '/cabinet/roles',
            label: 'Должности',
            icon: <CrownOutlined />,
          },*!/
        ],
      },
      /!*{
        type: 'group',
        label: 'Дополнительный',
        children: [
          {
            key: '/cabinet/map-work',
            label: 'Карта',
            icon: <HeatMapOutlined />,
          },
        ],
      },*!/
    ];*/

		return menuUser;
	};

	return <MenuExtra ref={menuRef} items={getItems()} selectedKeys={currentPath} onClick={handleChangeMenu} />;
});
