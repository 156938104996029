import { DataNode } from 'antd/es/tree';
import dayjs, { Dayjs } from 'dayjs';
import { action, computed, makeObservable, observable } from 'mobx';

import { ItemContractorStore } from './item-contractor.store';
import { ItemFileStore } from './item-file.store';
import { ItemLocalStatusStore } from './item-local-status.store';
import { ItemReportStore } from './item-report.store';
import { ItemTaskRequestStore } from './item-task-request.store';
import { ItemTriggerStore } from './item-trigger.store';
import { ItemObjectTypes, ItemTypeGroupFiles, MethodsRequest } from '../../constants';
import { ItemTypes, ItemTypesDrawerMapping } from '../../constants/item-types';
import { API } from '../../core';
import { ErrorAPI } from '../../helpers';
import { DayJsNull } from '../../interfaces';
import { Item } from '../../interfaces/item';
import { ItemBackRequest, ItemIsDoneRequest } from '../../requests';
import { CoreResponse } from '../../responses';
import { CrudStore } from '../common/crud.store';
import { DrawerStore } from '../common/drawer.store';
import { ModalStore } from '../common/modal.store';
import { ItemStatusStore } from '../item-statuses';
import { TriggerWorkflowStore } from '../trigger-workflows';
import { UserStore } from '../users';

export class ItemStore extends CrudStore<Item> implements Item {
	PATH = 'items';

	@observable drawer = new DrawerStore();
	@observable viewer = new DrawerStore();
	@observable modal = new ModalStore();

	@observable _lft = 0;
	@observable _rgt = 0;
	@observable inside_uuid = '';
	@observable parent_id: number | null = null;
	@observable item_project_document_id: number | null = null;
	@observable creator_id = 0;
	@observable responsible_id: number | null = null;
	@observable item_type_id = ItemTypes.object;
	@observable item_status_id: number | undefined = undefined;
	@observable item_local_status_id = 0;
	@observable item_object_type_id: ItemObjectTypes | null = null;
	@observable local_status = new ItemLocalStatusStore(null);
	@observable order_number = 0;
	@observable current_year = Number(dayjs().format('YYYY'));
	@observable title = '';
	@observable price = 0;
	@observable contract_number = '';
	@observable description = '';
	@observable content = '';
	@observable cipher = '';
	@observable start_date: Dayjs | undefined = undefined;
	@observable end_date: Dayjs | undefined = undefined;
	@observable actual_date: Dayjs | undefined = undefined;
	@observable is_subcontract = false;
	@observable is_delete = false;
	@observable is_draft = false;
	@observable custom_status = '';
	@observable created_at = dayjs();
	@observable updated_at = dayjs();
	@observable deviation_days = 0;
	@observable children_list: Array<ItemStore> | undefined = undefined;

	@observable parent_object: ItemStore | null = null;
	@observable parent_volume: ItemStore | null = null;

	@observable creator = new UserStore(null);
	@observable responsible = new UserStore(null);
	@observable contractors: Array<ItemContractorStore> = [];
	@observable files: { [key in ItemTypeGroupFiles]: Array<ItemFileStore> } = {
		[ItemTypeGroupFiles.TechnicalDocumentation]: [],
		[ItemTypeGroupFiles.AdministrativeDocumentation]: [],
		[ItemTypeGroupFiles.DesignAssignments]: [],
		[ItemTypeGroupFiles.WorkResult]: [],
	};

	@observable triggers: ItemTriggerStore[] = [];

	@observable status = new ItemStatusStore(null);

	@observable is_done = false;
	@observable is_confirmed = false;
	@observable is_finish = false;

	@observable is_blocked = false;
	@observable user_blocked_id = 0;

	@observable result_report = '';
	@observable reports: Array<ItemReportStore> = [];
	@observable deadline_execute_date: DayJsNull = null;
	@observable count = 0;
	@observable tasks_requests: Array<ItemTaskRequestStore> = [];
	@observable task_requests_count = 0;

	@observable history: Item | null = null;

	@observable childrenShow = false;

	@observable trigger_workflows: Array<TriggerWorkflowStore> = [];

	constructor(initialData: Item | null) {
		super();
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@computed
	get startDateFormatted() {
		if (this.start_date) return this.start_date.format('DD MMMM YYYY');

		return undefined;
	}

	@computed
	get endDateFormatted() {
		if (this.end_date) return this.end_date.format('DD MMMM YYYY');

		return undefined;
	}

	@computed
	get actualDateFormatted() {
		if (this.actual_date) return this.actual_date.format('DD MMMM YYYY HH:mm');

		return undefined;
	}

	@computed
	get nameByType(): string {
		return ItemTypesDrawerMapping[this.item_type_id];
	}

	@computed
	get getNewStatus() {
		return this.item_object_type_id === ItemObjectTypes.DISTRIBUTION_NETWORKS ? 1 : 19;
	}

	@computed
	get isSendConfirm() {
		return [3, 4].includes(this.local_status.id);
	}

	@computed
	get localStatus() {
		return this.local_status.status;
	}

	@computed
	get canConfirm() {
		return [3].includes(this.local_status.id);
	}

	@computed
	get isComplete() {
		return [4].includes(this.local_status.id);
	}

	@computed
	get lastReport() {
		if (this.reports[0]) {
			return this.reports[0];
		}

		return new ItemReportStore(null);
	}

	@computed
	get isExistChildren() {
		return this.count > 0;
	}

	@computed
	get objectID(): number {
		switch (this.item_type_id) {
			case ItemTypes.volume:
				return this.parent_id || 0;
			case ItemTypes.tasks:
				return this.parent_object?.id || 0;
			default:
				return this.id;
		}
	}

	@computed
	get historyTree(): Array<DataNode> {
		const complete: DataNode[] = [];

		if (!this.history) {
			return complete;
		}

		complete.push({
			title: `(${this.local_status.status}) ${this.history.title} `,
			key: this.history.id,
		});

		if (Array.isArray(this.history.children)) {
			complete[0].children = this.generateTree(this.history.children);
		}
		return complete;
	}

	@computed
	get taskRequestIDs() {
		return this.tasks_requests.map((task_request) => task_request.responsible_id).flat();
	}

	@action.bound
	setIsBlocked(value = true) {
		this.is_blocked = value;
	}

	@action.bound
	async sendTaskRequest() {
		try {
			// Создаем инстанс стора
			const taskRequest = new ItemTaskRequestStore(null);

			// Указываем ID
			taskRequest.setItemId(this.id);

			// Выполняем запрос
			await taskRequest.send();
		} catch (e) {
			console.error(`Error in method sendTaskRequest: `, e);
		}
	}

	@action.bound
	setChildrenShow(value = true) {
		this.childrenShow = value;
	}

	@action.bound
	createParentObjectWrapper() {
		this.parent_object = new ItemStore(null);
	}

	@action.bound
	clearParentObjectWrapper() {
		this.parent_object = null;
	}

	@action.bound
	createParenVolumeWrapper() {
		this.parent_volume = new ItemStore(null);
	}

	@action.bound
	clearParentVolumeWrapper() {
		this.parent_volume = null;
	}

	@action.bound
	setTypeObject() {
		this.item_type_id = ItemTypes.object;
	}

	@action.bound
	setTypeVolume() {
		this.item_type_id = ItemTypes.volume;
	}

	@action.bound
	setTypeTask() {
		this.item_type_id = ItemTypes.tasks;
	}

	@action.bound
	setItemObjectType(value: ItemObjectTypes) {
		this.item_object_type_id = value;
	}

	@action.bound
	setParentObject(value: ItemStore) {
		this.parent_object = value;
	}

	@action.bound
	setParentVolume(value: ItemStore) {
		this.parent_volume = value;
	}

	@action.bound
	async generate(volumes: any) {
		this.setIsLoading(true);

		try {
			await API.request(`items/generate`, {
				method: MethodsRequest.Post,
				body: API.getFormData({
					object_id: this.id,
					volumes,
				}),
			});
		} catch (e) {
			ErrorAPI('generate', e);
		} finally {
			this.setIsLoading(false);
		}
	}

	// Сохраняем файлы отчета
	@action.bound
	async uploadFileReport(file: File) {
		try {
			await API.request(`item-file-report`, {
				method: MethodsRequest.Post,
				body: API.getFormData({
					item_id: this.id,
					file,
				}),
			});
		} catch (e) {
			console.error(`Error in method uploadFileReport : `, e);
		}
	}

	// Сообщить о завершении работ
	@action.bound
	async sendIsDone(data: ItemIsDoneRequest) {
		try {
			this.setIsLoading(true);

			// Инстанс отчета
			const instanceReport = new ItemReportStore(null);

			// Отправляем запрос на создание
			await instanceReport.create({ item_id: this.id, comment: data.comment }, false);

			// Загружаем файлы
			await instanceReport.uploadFiles(data.files);

			// Отправляем обновленный статус
			await this.update({
				is_done: data.isDone,
			});
		} catch (e) {
			console.error(`Error in method sendIsDone: `, e);
		} finally {
			this.setIsLoading(false);
		}
	}

	// Вернуть на доработку
	@action.bound
	async sendBack(data: ItemBackRequest) {
		try {
			this.setIsLoading(true);

			// Инстанс отчета
			const instanceReport = new ItemReportStore(null);

			// Отправляем запрос на создание
			await instanceReport.create({ item_id: this.id, comment: data.comment }, false);

			// Загружаем файлы
			await instanceReport.uploadFiles(data.files);

			// Отправляем обновленный статус
			await this.update({
				is_return: true,
			});
		} catch (e) {
			console.error(`Error in method sendBack: `, e);
		}
	}

	@action.bound
	async getChildren() {
		try {
			const { data } = await API.request<CoreResponse<Item[]>>(`${this.PATH}/children/${this.id}`);

			this.children_list = (data || []).map((item) => new ItemStore(item));
		} catch (e) {
			console.error(`Error in method getChildren : `, e);
		}
	}

	@action.bound
	async getHistory() {
		try {
			this.history = await API.request<Item>(`${this.PATH}/history/${this.objectID}`);
		} catch (e) {
			console.error(`Error in method getHistory : `, e);
		}
	}

	@action.bound
	setIsDraft(value = true) {
		this.is_draft = value;
	}

	@action.bound
	async enabledEdit() {
		try {
			await API.request(`item-blocked/${this.id}`, {
				method: MethodsRequest.Post,
				body: API.getFormData({
					_method: MethodsRequest.Put,
				}),
			});
		} catch (e) {
			ErrorAPI('enabledEdit', e);
		}
	}

	getFiles(type: ItemTypeGroupFiles) {
		return computed(() => {
			return this.files[type] || [];
		}).get();
	}

	generateTree(data: Item[]): DataNode[] {
		if (data.length > 0) {
			return data.map((item) => {
				return {
					title: `(${item.local_status?.status || 'Не установлен'}) ${item.title} `,
					key: item.id,
					selectable: true,
					children: this.generateTree(item.children || []),
				};
			});
		} else {
			return [];
		}
	}

	@action
	fillStore(data: Item) {
		const {
			id,
			_lft,
			_rgt,
			inside_uuid,
			parent_id,
			item_project_document_id,
			creator_id,
			responsible_id,
			item_type_id,
			item_status_id,
			item_local_status_id,
			item_object_type_id,
			local_status,
			order_number,
			current_year,
			title,
			price,
			contract_number,
			description,
			content,
			cipher,
			start_date,
			end_date,
			actual_date,
			is_subcontract,
			is_delete,
			is_draft,
			custom_status,
			created_at,
			updated_at,
			creator,
			responsible,
			contractors,
			files,
			deviation_days,
			children_list,
			triggers,
			parent_object,
			parent_volume,
			status,
			is_done,
			is_confirmed,
			is_finish,
			is_blocked,
			user_blocked_id,
			result_report,
			reports,
			deadline_execute_date,
			count,
			tasks_requests,
			task_requests_count,
			trigger_workflows,
		} = data;

		this.id = id;
		this._lft = _lft;
		this._rgt = _rgt;
		this.inside_uuid = inside_uuid;
		this.parent_id = parent_id;
		this.item_project_document_id = item_project_document_id;
		this.creator_id = creator_id;
		this.responsible_id = responsible_id;
		this.item_type_id = item_type_id;
		this.item_status_id = item_status_id;
		this.item_local_status_id = item_local_status_id;
		this.item_object_type_id = item_object_type_id;
		this.local_status = new ItemLocalStatusStore(local_status);
		this.order_number = order_number;
		this.current_year = current_year;
		this.title = title;
		this.price = price;
		this.contract_number = contract_number;
		this.description = description;
		this.content = content;
		this.cipher = cipher;
		this.start_date = start_date ? dayjs(start_date) : undefined;
		this.end_date = end_date ? dayjs(end_date) : undefined;
		this.actual_date = actual_date ? dayjs(actual_date) : undefined;
		this.is_subcontract = is_subcontract;
		this.is_delete = is_delete;
		this.is_draft = is_draft;
		this.custom_status = custom_status;
		this.created_at = dayjs(created_at);
		this.updated_at = dayjs(updated_at);
		this.creator = new UserStore(creator);
		this.responsible = new UserStore(responsible);
		this.contractors = (contractors || []).map((contractor) => new ItemContractorStore(contractor));
		this.deviation_days = deviation_days || 0;
		this.triggers = (triggers || []).map((trigger) => new ItemTriggerStore(trigger));
		this.parent_object = new ItemStore(parent_object);
		this.parent_volume = new ItemStore(parent_volume);
		this.status = new ItemStatusStore(status);
		this.is_done = is_done;
		this.is_confirmed = is_confirmed;
		this.is_finish = is_finish;
		this.is_blocked = is_blocked;
		this.user_blocked_id = user_blocked_id;
		this.result_report = result_report;
		this.reports = (reports || []).map((report) => new ItemReportStore(report));
		this.deadline_execute_date = deadline_execute_date ? dayjs(deadline_execute_date) : null;
		this.count = count;
		this.tasks_requests = (tasks_requests || []).map((task_request) => new ItemTaskRequestStore(task_request));
		this.task_requests_count = task_requests_count || 0;

		const techFiles = files?.[ItemTypeGroupFiles.TechnicalDocumentation];
		const adminFiles = files?.[ItemTypeGroupFiles.AdministrativeDocumentation];
		const designFiles = files?.[ItemTypeGroupFiles.DesignAssignments];

		if (techFiles) {
			this.files[ItemTypeGroupFiles.TechnicalDocumentation] = (techFiles || []).map((file) => new ItemFileStore(file));
		}

		if (adminFiles) {
			this.files[ItemTypeGroupFiles.AdministrativeDocumentation] = (adminFiles || []).map(
				(file) => new ItemFileStore(file),
			);
		}

		if (designFiles) {
			this.files[ItemTypeGroupFiles.DesignAssignments] = (designFiles || []).map((file) => new ItemFileStore(file));
		}

		if (Array.isArray(children_list) && children_list.length > 0) {
			this.children_list = children_list.map((item) => new ItemStore(item));
		}

		if (Array.isArray(trigger_workflows)) {
			this.trigger_workflows = (trigger_workflows || []).map(
				(trigger_workflow) => new TriggerWorkflowStore(trigger_workflow),
			);
		}
	}
}
