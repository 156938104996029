import { useEffect } from 'react';

import { EditOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';

import { useStores } from '../stores';
import { ItemListStore } from '../stores/item/item-list.store';

interface IconEditItemProps {
	item: ItemListStore;
}

export const IconEditItem = observer(({ item }: IconEditItemProps) => {
	const { authStore, itemDisabledStore } = useStores();

	useEffect(() => {
		// Обновляем
		console.log('Обновился список', itemDisabledStore.list);
	}, [itemDisabledStore.list]);

	/*console.log(
		'variant',
		item.is_blocked,
		item.user_blocked_id !== authStore.current.id,
		itemDisabledStore.list,
		item.id,
	);*/

	if ((item.is_blocked && item.user_blocked_id !== authStore.current.id) || itemDisabledStore.list[item.id]) {
		return (
			<Tooltip title={`Сейчас в нем работает : Иванов Иван Иванович`}>
				<EditOutlined style={{ fontSize: 20, color: 'red' }} />
			</Tooltip>
		);
	}

	return null;
});
