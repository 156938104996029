import { useEffect, useState } from 'react';

import { Form, FormInstance, Tabs, TabsProps } from 'antd';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';

import { ChronologyTab, ContractorsTab, FilesTab, InformationTab } from './Tabs';
import { ITEM_TABS } from '../../../../../constants';
import { ItemStore, useStores } from '../../../../../stores';

interface ItemObjectFormProps {
	isBlockedItem: boolean;
	form: FormInstance;
	onSend: (values: any) => void;
	onError: () => void;
	onChangeForm: () => void;
}

export const ItemObjectForm = observer(
	({ isBlockedItem, form, onSend, onError, onChangeForm }: ItemObjectFormProps) => {
		const { itemsStore, responsiblesStore, contractorsStore } = useStores();
		const { selected: objectItem } = itemsStore;

		const [currentTab, setCurrentTab] = useState<string>(ITEM_TABS.OBJECT.CHRONOLOGY.key);

		// Табы
		const itemTabs: TabsProps['items'] = [
			{
				key: ITEM_TABS.OBJECT.DATA.key,
				label: ITEM_TABS.OBJECT.DATA.value,
				children: <InformationTab form={form} />,
				forceRender: true,
			},
			{
				key: ITEM_TABS.OBJECT.CONTRACTORS.key,
				label: ITEM_TABS.OBJECT.CONTRACTORS.value,
				children: <ContractorsTab form={form} />,
				forceRender: true,
			},
			{
				key: ITEM_TABS.OBJECT.FILES.key,
				label: ITEM_TABS.OBJECT.FILES.value,
				children: <FilesTab form={form} />,
				forceRender: true,
			},
			{
				key: ITEM_TABS.OBJECT.CHRONOLOGY.key,
				label: ITEM_TABS.OBJECT.CHRONOLOGY.value,
				children: <ChronologyTab form={form} />,
				forceRender: true,
			},
			/*{
      key: ITEM_TABS.OBJECT.RELATIONS.key,
      label: ITEM_TABS.OBJECT.RELATIONS.value,
      forceRender: true,
      disabled: true,
    },*/
		];

		// Init полей
		const getFields = (currentItem: ItemStore) => {
			return {
				id: currentItem.id,
				_lft: currentItem._lft,
				_rgt: currentItem._rgt,
				parent_id: currentItem.parent_id,
				item_project_document_id: currentItem.item_project_document_id,
				creator_id: currentItem.creator_id,
				responsible_id: currentItem.responsible_id,
				item_type_id: currentItem.item_type_id,
				item_status_id: currentItem.item_status_id,
				item_object_type_id: currentItem.item_object_type_id,
				order_number: currentItem.order_number,
				current_year: currentItem.current_year,
				title: currentItem.title,
				price: currentItem.price,
				contract_number: currentItem.contract_number,
				description: currentItem.description,
				content: currentItem.content,
				cipher: currentItem.cipher,
				start_date: currentItem.start_date,
				end_date: currentItem.end_date,
				actual_date: currentItem.actual_date,
				is_subcontract: currentItem.is_subcontract,
				is_delete: currentItem.is_delete,
				is_draft: currentItem.is_draft,
				custom_status: currentItem.custom_status,
				created_at: currentItem.created_at,
				updated_at: currentItem.updated_at,
				contractors: currentItem.contractors.map((contractor) => ({
					id: contractor.id,
					contractor_id: contractor.contractor_id,
					subject_contract: contractor.subject_contract,
				})),
			};
		};

		// Данные для формы
		useEffect(() => {
			(async () => {
				await responsiblesStore.getAll();
				await contractorsStore.all.getList();
			})();
		}, []);

		// Инит данных и их обновление от side effect
		useEffect(() => {
			if (objectItem.drawer.isShow && !objectItem.isLoading) {
				updateFields();
			}
		}, [objectItem.drawer.isShow, objectItem.isLoading]);

		// Когда создаем новый объект
		useEffect(() => {
			if (objectItem.isNewStore && objectItem.drawer.isShow) {
				// Дефолтное заполнение
				form.setFieldsValue({
					item_status_id: objectItem.getNewStatus,
					start_date: dayjs(),
					end_date: dayjs().add(30, 'days'),
				});
			}
		}, [objectItem.item_object_type_id, objectItem.drawer.isShow]);

		// Очистка выбранного таба
		useEffect(() => {
			if (!objectItem.drawer.isShow) {
				setCurrentTab(ITEM_TABS.OBJECT.DATA.key);
			}
		}, [objectItem.drawer.isShow]);

		// Смена табов
		const handleOnChangeTab = (keyTab: string) => {
			setCurrentTab(keyTab);
		};

		const updateFields = () => {
			form.setFieldsValue(getFields(objectItem));
		};

		return (
			<>
				<Form
					disabled={isBlockedItem}
					name={'form-object'}
					form={form}
					layout={'vertical'}
					autoComplete="off"
					onFinish={async (values) => {
						await onSend(values);
						updateFields();
					}}
					onFinishFailed={onError}
					onValuesChange={onChangeForm}
				>
					<Tabs activeKey={currentTab} items={itemTabs} onChange={handleOnChangeTab} />
				</Form>
			</>
		);
	},
);
