import { action, makeObservable, observable } from 'mobx';

import { ItemDisabled, ItemEnabledDisabled } from '../../interfaces';

export class ItemDisabledStore {
	@observable list: ItemDisabled = {};

	constructor() {
		makeObservable(this);
	}

	@action.bound
	add(data: ItemEnabledDisabled) {
		const { user_id, item_id } = data;
		const list = { ...this.list };

		list[item_id] = user_id;

		this.list = list;
	}

	@action.bound
	remove(data: ItemEnabledDisabled) {
		const { item_id } = data;
		const list = { ...this.list };

		console.log({ ...this.list });

		delete list[item_id];

		console.log('after remove', list);

		this.list = list;
	}
}
