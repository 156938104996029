import { useNavigate } from 'react-router-dom';

import { Exit } from './Exit/Exit';
import { Menu } from './MenuBlock/Menu';
import { SiderExtra } from './Sider.styled';
import { UserBlock } from './UserBlock/UserBlock';
import { Logotype } from '../../../common';

export const Sider = () => {
	const navigate = useNavigate();

	const handleOnClick = () => navigate('/cabinet');

	return (
		<SiderExtra width={300}>
			<Logotype />
			<UserBlock />
			<Menu />

			<Exit />
		</SiderExtra>
	);
};
