import { ReactNode } from 'react';

import { Container } from './Actions.styled';

interface ActionsProps {
	children?: ReactNode;
}

export const Actions = ({ children = null }: ActionsProps) => {
	return <Container>{children}</Container>;
};
