import { LogotypeContainer } from './Logotype.styled';

interface LogotypeProps {}

export const Logotype = ({}: LogotypeProps) => {
	return (
		<LogotypeContainer>
			Fortuna<span>System</span>
		</LogotypeContainer>
	);
};
